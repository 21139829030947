import React from 'react';
import styled from 'styled-components';

import { Regular } from '../text';
import { renderFaqAnswerText } from '../../utils/faqTextRenderer';

export const QuestionAndAnswer = ({ id, question, answer, location }) => {
  const answerRef = React.useRef(null);
  const questionRef = React.useRef(null);
  const [isOpen, setIsOpen] = React.useState(false);
  
  React.useLayoutEffect(() => {
    if (location.hash.substring(1) === id && typeof window !== 'undefined') {
      setIsOpen(true);
    }
  }, [location, questionRef.current]);

  return (
    <React.Fragment>
      <QuestionDt id={id} ref={questionRef} onClick={() => setIsOpen(prevIsOpen => !prevIsOpen)}>
        {question}
      </QuestionDt>
      <AnswerDd isOpen={isOpen} maxHeight={answerRef?.current?.clientHeight}>
        <div ref={answerRef} css={{ display: 'flex', flexDirection: 'column' }}>
          {renderFaqAnswerText(answer.json)}
        </div>
      </AnswerDd>
    </React.Fragment>
  );
};

const QuestionDt = styled(Regular).attrs(props => ({
  as: 'dt',
  align: 'left',
}))`
  cursor: pointer;
  transition: ${({ theme }) => theme.transitions.color};
  padding-bottom: ${({ theme }) => theme.spacing(2.5)};

  &:hover {
    color: ${({ theme }) => theme.color.nbPrimary};
  }

  @media ${({ theme }) => theme.breakpoints.sm} {
    font-size: ${({ theme }) => theme.text.medium.size};
    line-height: ${({ theme }) => theme.text.medium.lineHeight};
  }
`;

const AnswerDd = styled.dd`
  height: auto;
  overflow: hidden;
  transform: translateY(-${({ theme }) => theme.spacing(2.5)});
  max-height: ${({ isOpen, maxHeight }) => (isOpen ? maxHeight : '0')}px;
  transition: ${({ theme }) => theme.transitions.all};
`;
