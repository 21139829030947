import React from 'react';
import { graphql } from 'gatsby';
import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer';

import SEO from '../components/seo';
import config from '../config';
import Layout from '../components/layout';
import { Divider } from '../components/LandingPage/Divider';
import { H1, H2, H3 } from '../components/faq/Faq.StyledComponents';
import { navSections } from '../components/navbar';
import { navColorOptions } from '../components/navbar/navColors';
import { QuestionAndAnswer } from '../components/faq/QuestionAndAnswer';
import * as StyledComponents from '../components/faq/Faq.StyledComponents';
import { usePageMetaAttributes } from '../utils/usePageMetaAttributes';

const query = graphql`
  query FaqPageQuery {
    faqOrder: contentfulFaqOrder {
      categoryOrder {
        sectionTitle
      }
    }
    faqSections: allContentfulFaqSection {
      asArray: nodes {
        id
        sectionTitle
      }
    }
    faqQuestions: allContentfulFaqQuestion {
      asArray: nodes {
        faqQuestion
        faqAnswer {
          json
        }
        faqSection {
          id
          sectionTitle
        }
      }
    }
  }
`;

const formatId = question => {
  return question.toLowerCase()
    .replace(/\W/g, '-') // Replace non word characters
    .replace(/-+$/, '') // Remove trailing dashes
    .replace(/-{2,}/, '-'); // Remove extra dashes when there is more than 1 in a row
};

const Faq = ({ data, location }) => {
  const meta = usePageMetaAttributes('faq');
  const { faqQuestions, faqSections, faqOrder } = data;

  const faqs = React.useMemo(() => {
    const order = faqOrder.categoryOrder.map(({ sectionTitle }) => sectionTitle);

    return faqSections.asArray
      .sort((a, b) => {
        return order.indexOf(a.sectionTitle) - order.indexOf(b.sectionTitle);
      })
      .reduce((accumulator, section) => {
        accumulator[formatId(section.sectionTitle)] = {
          title: section.sectionTitle,
          items: faqQuestions.asArray
            .filter(question => {
              return question.faqSection.id === section.id;
            })
            .map(question => ({
              id: formatId(question.faqQuestion),
              question: question.faqQuestion,
              answer: question.faqAnswer,
            })),
        };

        return accumulator;
      }, new Object());
  }, [data]);

  React.useEffect(() => {
    if (location.hash && typeof window !== 'undefined') {
      window.scrollBy(0, -96); // scroll past nav
    }
  }, [location]);

  const onSupportChatClick = () => {
    if (window && window.Intercom) {
      // Show the bubble
      window.Intercom('update', {
        hide_default_launcher: false,
      });

      // Show the messenger panel
      window.Intercom('show');

      // Hide the bubble on close
      window.Intercom('onHide', function() {
        window.Intercom('update', {
          hide_default_launcher: true,
        });
      });
    }
  };

  return (
    <Layout navColor={navColorOptions.nbPrimary} currentSection={navSections.RESOURCES}>
      <SEO
        {...meta}
        schemaMarkup={JSON.stringify({
          '@context': 'https://schema.org',
          '@type': 'FAQPage',
          mainEntity: faqQuestions.asArray.map(item => ({
            '@type': 'Question',
            name: item.faqQuestion,
            acceptedAnswer: {
              '@type': 'Answer',
              text: documentToPlainTextString(item.faqAnswer.json)
            }
          }))
        })}
      />
      <StyledComponents.Hero>
        <Divider />
        <H1>Namebase & Handshake Frequently Asked Questions (FAQ's)</H1>
        <H2>We have the answers.</H2>
        <Divider />
      </StyledComponents.Hero>
      <StyledComponents.Layout>
        <StyledComponents.Nav>
          {Object.keys(faqs).map(sectionId => (
            <StyledComponents.NavLink
              key={`${sectionId}-nav`}
              href={`#${sectionId}`}
              isActive={location.hash.substring(1) === sectionId}>
              {faqs[sectionId].title}
            </StyledComponents.NavLink>
          ))}
        </StyledComponents.Nav>
        <StyledComponents.Sections>
          {Object.entries(faqs).map(([id, section]) => (
            <section id={id} key={id}>
              <H3>{section.title}</H3>
              <dl css={{ margin: 0 }}>
                {section.items.map(item => (
                  <QuestionAndAnswer key={item.id} location={location} {...item} />
                ))}
              </dl>
            </section>
          ))}
          <div>
            <StyledComponents.Cta>
              Still have questions? <span onClick={onSupportChatClick}>Try our support chat</span> or <a href={config.feedbackUrl} target="_blank" rel="noopener noreferrer">submit your question here</a>.
            </StyledComponents.Cta>
          </div>
        </StyledComponents.Sections>
      </StyledComponents.Layout>
    </Layout>
  );
};

export { query };
export default Faq;
