import styled from 'styled-components';
import { Divider } from '../LandingPage/Divider';
import { DisplayTiny, Small, Huge, Large } from '../text';

export const Hero = styled.div`
  background: ${({ theme }) => theme.color.nbPrimary};
  padding-top: ${({ theme }) => theme.spacing(11)};
  padding-bottom: ${({ theme }) => theme.spacing(15)};

  ${Divider} {
    margin: 0 auto;
  }

  & > * + * {
    margin-top: ${({ theme }) => theme.spacing(2.5)};
  }

  h1 {
    margin-top: ${({ theme }) => theme.spacing(4)};
  }
  h2 {
    margin-bottom: ${({ theme }) => theme.spacing(4)};
  }
`;

export const H1 = styled(DisplayTiny).attrs(props => ({
  as: 'h1',
  align: 'center',
  weight: 'bold',
  colored: 'white',
}))`
  margin: 0 auto;
  max-width: 666px;
`;

export const H2 = styled(Huge).attrs(props => ({
  as: 'h2',
  align: 'center',
  weight: 'medium',
  colored: 'white',
}))``;

export const H3 = styled(Large).attrs(props => ({
  as: 'h3',
  weight: 'bold',
  colored: 'nbPrimary',
}))`
  padding-bottom: ${({ theme }) => theme.spacing(4)};
`;

export const Nav = styled.nav`
  top: ${props => props.theme.spacing(13)};
  left: 0;
  position: sticky;
  margin-top: 6px;
  align-self: flex-start;

  & > * + * {
    margin-top: ${({ theme }) => theme.spacing(2)};
  }

  @media ${({ theme }) => theme.breakpoints.sm} {
    display: none;
  }
`;

export const NavLink = styled(Small).attrs(props => ({
  as: 'a',
  colored: props.isActive ? 'nbPrimary' : 'grey60',
}))`
  display: block;
  transition: ${({ theme }) => theme.transitions.color};
  line-height: ${({ theme }) => theme.text.large.lineHeight};

  &:hover,
  &:focus {
    color: ${({ theme }) => theme.color.nbPrimary};
  }
`;

export const Layout = styled.div`
  width: max-content;
  margin: 0 auto;
  display: flex;
  padding-top: ${({ theme }) => theme.spacing(7)};
  padding-bottom: ${({ theme }) => theme.spacing(20)};
  justify-content: center;

  @media ${({ theme }) => theme.breakpoints.sm} {
    width: 100%;
    padding-top: ${({ theme }) => theme.spacing(5)};
  }
`;

export const Sections = styled(Layout)`
  max-width: ${({ theme }) => theme.spacing(69)};
  padding-top: 0;
  padding-bottom: 0;
  flex-direction: column;

  & > div,
  & > section {
    padding-left: 80px;
  }

  & > * + * {
    margin-top: ${({ theme }) => theme.spacing(8)};
  }

  @media ${({ theme }) => theme.breakpoints.sm} {
    width: 100%;
    max-width: 100%;
    padding-left: ${({ theme }) => theme.spacing(3)};
    padding-right: ${({ theme }) => theme.spacing(3)};

    & > div,
    & > section {
      width: 100%;
      padding: 0;
    }

    & > * + * {
      margin-top: ${({ theme }) => theme.spacing(5)};
    }
  }
`;

export const Cta = styled(Small).attrs(props => ({
  as: "p",
  colored: 'grey70',
}))`
  a,
  span {
    color: ${({ theme }) => theme.color.nbPrimary};
    cursor: pointer;
    transition: ${({ theme }) => theme.transitions.color};

    &:hover,
    &:focus {
      color: ${({ theme }) => theme.color.blue50};
    }
  }
`;
