// TODO should abstract text renderers and combine w/ the other richTextRenderer file

import React from 'react';
import styled from 'styled-components';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS, INLINES, MARKS } from '@contentful/rich-text-types';
import { Regular, Small } from '../components/text';

const Bold = styled.span`
  font-weight: 500;
`;

const Link = styled.a`
  color: ${props => props.theme.color.nbPrimary};
`;

const Hyperlink = ({ children, href }) => (
  <Link href={href} target={href.startsWith('#') ? null : '_blank'} rel="noopener noreferrer">
    {children}
  </Link>
);

const BodyCopy = styled(Small).attrs(props => ({ colored: 'grey90' }))`
  margin-bottom: ${props => props.theme.spacing(3)};
`;

const Header = styled(Regular)`
  margin-bottom: ${props => props.theme.spacing(1)};
`;

const Image = styled.img`
  display: block;
  margin: auto;
  width: 100%;
  height: auto;
  margin-bottom: ${props => props.theme.spacing(3)};
`;

const Video = styled.video`
  outline: none;
  max-width: 100%;
  align-self: center;
`;

const EmbeddedVideo = ({ url, type }) => {
  return (
    <Video controls>
      <source src={url} type={type} />
    </Video>
  );
};

const EmbeddedImage = ({ url, description }) => {
  return <Image alt={description} src={url} />;
};

const makeId = string =>
  string
    .toLowerCase()
    .trim()
    .replace(/ /g, '-');

const options = {
  renderMark: {
    [MARKS.BOLD]: text => <Bold>{text}</Bold>,
  },
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => <BodyCopy as="p">{children}</BodyCopy>,
    [BLOCKS.HEADING_1]: (node, children) => <Header id={makeId(children[0])}>{children[0]}</Header>,
    [BLOCKS.EMBEDDED_ASSET]: asset => {
      const {
        url,
        contentType,
        details: { width },
      } = asset.data.target.fields.file['en-US'];
      const description = (asset.data.target.fields.description || {})['en-US'];
      // see https://www.contentful.com/developers/docs/references/images-api/
      if (contentType.includes('image')) {
        const desiredWidth = width < 720 ? width : 720;
        const modifiedUrl = url + `?fit=pad&w=${desiredWidth}`;
        return <EmbeddedImage url={modifiedUrl} description={description} />;
      } else if (contentType.includes('video')) {
        return <EmbeddedVideo url={url} type={contentType} />;
      } else {
        return null;
      }
    },
    [INLINES.HYPERLINK]: item => (
      <Hyperlink href={item.data.uri}>{item.content[0].value}</Hyperlink>
    ),
  },
};

export const renderFaqAnswerText = richTextJson => documentToReactComponents(richTextJson, options);
